<template>
  <div class="q-pa-md">
    <div class="body-container q-mx-auto">
      <h2>Bienvenid@ {{ user.names }}</h2>

      <div class="row q-mb-md" v-if="!user.email">
        <div class="col-12">
          <q-banner :class="$q.dark.isActive ? 'bg-orange-9' : 'bg-orange-3'">
            <template v-slot:avatar>
              <q-icon name="warning" />
            </template>
            <p>
              Se recomienda que configure un <b>correo personal</b>, ya que este
              podrá ser usado para recuperar su cuenta en caso de que olvide su
              <b>usuario</b> o <b>contraseña</b>.
            </p>
            <p class="q-ma-none">
              De la misma forma recordarle que cambie su contraseña por defecto
              a una segura para mantener protegidos sus datos.
            </p>
            <template v-slot:action>
              <q-btn
                flat
                label="configurar"
                :to="`/profile/${user.id}/setting`"
              />
            </template>
          </q-banner>
        </div>
      </div>

      <div class="c-shortcut-pane rounded-borders">
        <p class="text-overline q-ml-md">Accesos directos</p>
        <div class="row q-pa-md q-col-gutter-md">
          <div class="col-12 col-md-6">
            <q-list bordered class="rounded-borders">
              <q-item
                clickable
                class="rounded-borders q-pa-sm"
                :to="`/profile/${user.id}/setting`"
              >
                <q-item-section avatar>
                  <q-avatar
                    color="blue-10"
                    text-color="white"
                    size="4rem"
                    rounded
                  >
                    <q-icon name="manage_accounts" size="3rem" />
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label class="text-subtitle1"
                    >Configuración personal</q-item-label
                  >
                  <q-item-label caption lines="2">
                    Modifica tu nombre de usuario, contraseña, email y demás
                    información personal
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>

          <div class="col-12 col-md-6">
            <q-list bordered class="rounded-borders">
              <q-item
                clickable
                class="rounded-borders q-pa-sm"
                to="/boletas-de-pago"
              >
                <q-item-section avatar>
                  <q-avatar
                    color="green"
                    text-color="white"
                    size="4rem"
                    rounded
                  >
                    <q-icon name="request_quote" size="3rem" />
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label class="text-subtitle1"
                    >Mis boletas de pago</q-item-label
                  >
                  <q-item-label caption lines="2">
                    Revisa aquí tus boletas de pago
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>

      <!-- LISTADO DE DOCUMENTOS SGI -->
      <q-card
        class="c-info-pane rounded-borders q-mt-lg"
        v-if="sgi_documents.length > 0"
      >
        <p class="text-overline q-ml-md">Documentación SGI</p>
        <div class="row q-pa-md q-col-gutter-md">
          <div
            class="col-12 col-md-6"
            v-for="(item, i) in sgi_documents"
            :key="i"
          >
            <q-list bordered class="rounded-borders">
              <q-item
                clickable
                class="rounded-borders q-pa-sm"
                @click="openFile(item)"
              >
                <q-item-section avatar>
                  <q-avatar
                    color="red-10"
                    text-color="white"
                    size="4rem"
                    rounded
                  >
                    <q-icon name="description" size="3rem" />
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label class="text-subtitle1">{{
                    item.name
                  }}</q-item-label>
                  <q-item-label caption lines="2">
                    {{ item.description }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
        <q-inner-loading :showing="loading">
          <q-spinner-gears size="50px" color="indigo" />
        </q-inner-loading>
      </q-card>
    </div>
  </div>
</template>

<script>
import pb from "@/pocketbase.js";
import { useQuasar } from "quasar";

import ShowDocument from "@/components/Paystub/ShowDocument.vue";

export default {
  name: "HomeView",
  data: () => ({
    $q: useQuasar(),
    user: null,
    loading: false,
    baseUrl: "",
    sgi_documents: [],
  }),
  beforeMount() {
    document.title = "Celicon APP - Inicio";
    this.user = pb.authStore.model;
    this.getSgiDocuments();
    this.baseUrl = pb.baseUrl;
  },
  methods: {
    async getSgiDocuments() {
      try {
        this.loading = true;
        this.sgi_documents = await pb
          .collection("view_user_sgi_document")
          .getFullList({
            sort: "-created",
          });
        this.loading = false;
      } catch (ex) {
        this.loading = false;
        if (ex.data) {
          if (ex.data.code) {
            this.$q.notify({
              type: "warning",
              progress: true,
              message: ex.data.code,
              caption: ex.data.message,
            });
            return;
          }
        }
        this.$q.notify({
          type: "negative",
          progress: true,
          message: "ERROR",
          caption: ex.message,
        });
      }
    },
    async openFile(document) {
      const fileToken = await pb.files.getToken();
      this.$q
        .dialog({
          component: ShowDocument,
          componentProps: {
            url: `${pb.baseUrl}/api/files/x877j2bv59ycrim/${document.id}/${document.file}?token=${fileToken}`,
          },
        })
        .onDismiss(() => {});
    },
  },
};
</script>
<style scoped>
.c-shortcut-pane {
  border-style: dashed;
  border-width: 0.1rem;
  width: 100%;
  border-color: grey;
}

.c-info-pane {
  border-style: solid;
  border-width: 0.1rem;
  width: 100%;
  border-color: grey;
}

.c-shortcut-access {
  background-color: rgba(55, 166, 221, 0.4);
  border-radius: 1rem;
}
</style>
