<template>
  <q-dialog>
    <q-card
      class="q-pa-none q-ma-none column full-height"
      style="width: 800px; max-width: 90vw"
    >
      <iframe :src="url" frameborder="0" width="100%" height="100%"></iframe>
    </q-card>
  </q-dialog>
</template>
<script>
import pb from "@/pocketbase.js";
import { useQuasar } from "quasar";

export default {
  name: "ShowDocument",
  props: {
    url: String,
  },
  data: () => ({
    $q: useQuasar(),
  }),
};
</script>
